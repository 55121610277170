import {Resume} from "./components/Resume";
import {ValueStatement} from './components/ValueStatement';

const AppRoutes = [
    {
        index: true,
        element: <ValueStatement/>
    },
    {
        path: '/resume',
        element: <Resume/>
    }
];

export default AppRoutes;