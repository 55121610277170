import React, {Component} from 'react';
import {Container, List, Label, Card, CardHeader, CardBody} from "reactstrap";
import {Link} from "react-router-dom";
import './Resume.css';

export class Resume extends Component {
    static displayName = Resume.name;

    render() {
        return (
            <Container className="resume_layoutContainer">
                <Container className="resume_profileContainer shadow-lg">
                    <Container className="resume_innerProfileContainer">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6">                                
                                <img src="images/andreavatar.png" alt="Andres Daba-Hamilton" className="resume_profilePic d-block mx-auto mx-md-0"  />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <Container className="resume_headerRight">
                                    <Container>
                                        <div className="row">
                                            <Container className="col-12 resume_textRight">
                                                <Label>Andres Dabai-Hamilton</Label>
                                            </Container>
                                        </div>
                                        <div className="row">
                                            <Container className="col-12 resume_textRight">
                                                <Label>(916) 337-6542</Label>
                                            </Container>
                                        </div>
                                        <div className="row">
                                            <Container className="col-12 resume_textRight">
                                                <Link to="mailto:andre@andresresume.com">
                                                    <Label>andre@andresresume.com</Label>
                                                </Link>
                                            </Container>
                                        </div>
                                        <div className="row resume_textRight">
                                            <Container className="col-12">
                                                <Label>Download other versions:</Label>
                                                &nbsp;&nbsp;                                                
                                                <a href="/doc/andres-resume.pdf">
                                                    <Label>PDF</Label>
                                                </a>
                                                &nbsp;&nbsp;
                                                <a href="/doc/andres-resume.docx">
                                                    <Label>DOCX</Label>    
                                                </a>                                                
                                            </Container>                                            
                                        </div>
                                    </Container>
                                </Container>
                            </div>
                        </div>
                    </Container>
                </Container>

                <Container className="resume_employmentExperience">
                    <h2>Employment Experience</h2>
                </Container>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Dec 2023 – May 2024</strong>
                        &nbsp;&nbsp;
                        Advanced Technical Advisor (Tier II) for BendPak
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                As an advanced technical advisor with a strong focus on customer service, 
                                                I excel in direct communication with clients. My expertise extends to 
                                                company damage mitigation, where I proactively address challenges and 
                                                minimize adverse impacts. With a keen eye for detail and a commitment 
                                                to client satisfaction, I contribute to seamless operations 
                                                and positive outcomes.
                                            </p>
                                        </li>                                        
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Apr 2024 – Dec 2023</strong>
                        &nbsp;&nbsp;
                        Technical Advisor (Tier I) for BendPak
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Customer service, technical advisor, problem solving
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Apr 2023 – Dec 2023</strong>
                        &nbsp;&nbsp;
                        Revenue Business Specialist II for California Family Fitness
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Direct auditing of all incoming revenue, Enforcing Corporate Policies, Sales commissions
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>


                <Card className="my-2">
                    <CardHeader>
                        <strong>Nov 2018- Nov 2021</strong>
                        &nbsp;&nbsp;
                        Accounts Receivable Specialist for California Family Fitness
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Managing members, dealing with bad debts, Clerical services, contacting and collecting clients, servicing
                                                clients, billing and auditing contracts
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong> Apr 2018- Nov 2018</strong>
                        &nbsp;&nbsp;
                        Electronics Team Member for Walmart
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                General IT skills (phone/laptop repairs), online order processing, Customer service
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Aug 2017- Mar 2018</strong>
                        &nbsp;&nbsp;
                        Guest Service Team Leader for Target
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Scheduling, create new and efficient Cashier and Team Member Routines.
                                                Ordering supplies. Monitored and enforced corporate policies. Resolved all Guest Service issues.
                                                Developed and trained all Front end Talent. Asset Protection
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Aug 2016-Aug 2017</strong>
                        &nbsp;&nbsp;
                        Cashier for Target
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Basic Math Skills. Excellent interpersonal communication. Ability to effectively manage your Ɵme and
                                                prioritize tasks. Strong product knowledge and understanding of customers. Professional telephone etiquette.

                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Apr 2024 – Dec 2023</strong>
                        &nbsp;&nbsp;
                        Shift Leader for Papa John's
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Provided onsite training. Balanced cash registers and deposit drop offs. Provided Customer Service and
                                                created excellent guest to company relations. Mapped out delivery routes for drivers.

                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Feb 2015 - Aug 2015</strong>
                        &nbsp;&nbsp;
                        Team Member for Papa Johns
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Computed sales prices, total purchases and processed payments. Operated cash registers to process
                                                cash, check and credit card transactions. Replenished floor stock and processed shipments to ensure
                                                product availability for customers.
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <Card className="my-2">
                    <CardHeader>
                        <strong>Sep 2014-Jan 2015</strong>
                        &nbsp;&nbsp;
                        Shift Supervisor for McDonalds
                    </CardHeader>
                    <CardBody>
                        <Container>
                            <div className="row">
                                <div className="col-12">
                                    <List>
                                        <li>
                                            <p>
                                                Writing Schedules, Basic Management of Employees, Cash Counting/Bank Drops
                                            </p>
                                        </li>
                                    </List>
                                </div>
                            </div>
                        </Container>
                    </CardBody>
                </Card>

                <br/>
            </Container>
        );
    }
}