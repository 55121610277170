import {Component} from "react";
import {Container} from "reactstrap";
import './ValueStatement.css';

export class ValueStatement extends Component {
    render() {
        return (
            <Container className="valueStatement_container">
                <div className="row">
                    <div className="col-12 text-center">
                        <img className="valueStatement_profilePic" src="images/resumepic.png" alt="Andres Dabai-Hamilton"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Container className="valueStatement_textContainer">                            
                            
                            <p>
                                A reliable and friendly professional who quickly adapts to new concepts and acquires new skills. 
                                Passionate about achieving goals both in and out of the office, is dedicated to providing exceptional 
                                customer experiences while building trusting relationships. With over 3 years of managerial experience, 
                                6+ years in clerical administration, and 3 years in collection and bad debt management. Andres brings his expertise in 
                                areas such as customer service, inventory management, cash handling, scheduling, contract auditing, and billing.
                            </p>                                
                                
                        </Container>
                    </div>
                </div>
            </Container>
        );
    }
}